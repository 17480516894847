import React, { useContext, useState, useEffect } from "react";
import { Auth, Hub, API } from 'aws-amplify';
import { getUserInfo } from "../aws";
import axios from "axios";
import api from "../timestream"
import { getUserVehicle } from "../api";
// eslint-disable-next-line

const AuthContext = React.createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [currentUser, setCurrentUser] = useState()
    const [userDetails, setUserDetails] = useState();
    const [userInfo, setUserInfo] = useState()
    const [authData, setAuthData] = useState()
    const [loading, setLoading] = useState(true);
    // eslint-disable-next-line

    function setCurrentUserM(user) {
        if (!!user) {
            user['uid'] = user.username;
        }
    }
    async function signup(email, password, firstName, lastName, phoneNumber, organization, message) {
        try {
            const { user } = await Auth.signUp({
                username: email,
                password,
                attributes: {
                    email: email,          // optional
                    phone_number: phoneNumber,   // optional - E.164 number convention
                    given_name: firstName,
                    family_name: lastName,
                    picture: "https://gallery.yopriceville.com/var/resizes/Free-Clipart-Pictures/Sun-and-Moon-PNG/White_Moon_PNG_Clipart_Picture.png?m=1507172110",
                    // other custom attributes
                },
                autoSignIn: { // optional - enables auto sign in after user is confirmed
                    enabled: false,
                }
            });

            return {
                status: true,
                data: user,
            }
        } catch (e) {
            console.log(e)
            return {
                status: false,
                error: e.message
            }
        }

    }

    async function resendConfirmationCode(username) {
        try {
            await Auth.resendSignUp(username);
        } catch (err) {
            console.log('error resending code: ', err);
        }
    }

    async function confirmSignUp(email, password, firstName, lastName, phoneNumber, organization, message, code) {
        try {
            const res = await Auth.confirmSignUp(email, code, { forceAliasCreation: false });
            const user = await Auth.signIn(email, password);
            const userInfo = await Auth.currentUserInfo()
            await Auth.updateUserAttributes(user, {
                'custom:userID': userInfo.id,
                'custom:organization': organization
            });
            const data = {
                firstname: firstName,
                lastname: lastName,
                email: email,
                phone: phoneNumber.toString(),
                organization: organization,
                message: message,
                uid: userInfo.id,
                customer: false,
                number: "None",
                img: "https://gallery.yopriceville.com/var/resizes/Free-Clipart-Pictures/Sun-and-Moon-PNG/White_Moon_PNG_Clipart_Picture.png?m=1507172110",
            }

            API.post('users', "/users", {
                body: data
            }).then(function (response) {
                setCurrentUser(userInfo)
                setUserDetails(data)

            }).catch((e) => {
                console.log(e.response)
            })
            const userData = await Auth.currentAuthenticatedUser();
            const url = process.env.REACT_APP_ORGANIZATION_RELATION_URL
            const res2 = await axios.post(url + `organizationrelation/`, {
                userId: userInfo.id,
                organizationId: process.env.REACT_APP_SKYPAD_ID
            }, {
                headers: { Authorization: "Bearer " + userData.signInUserSession.accessToken.jwtToken }
            })
            console.log(res2)
            return res
        } catch (error) {
            console.log('error confirming sign up', error);
            return null
        }
    }

    async function login(email, password) {
        try {
            const user = await Auth.signIn(email, password);
            const data = await getDetails()

            return data
        } catch (e) {
            console.log(e)
            return {
                status: false,
                error: e.message,
            }
        }
    }

    async function logout(e, callback) {
        e.preventDefault()
        await Auth.signOut({ global: true });
        await setCurrentUser(null)
        await setUserDetails(null)
        await callback("/")
    }

    async function resetPassword(email) {
        try {
            const res = await Auth.forgotPassword(email)
            return {
                status: true,
                data: res
            }
        } catch (e) {
            console.log(e)
            return {
                status: false,
                error: e.message,
            }
        }
    }

    async function resetPasswordSubmit(email, code, password) {
        try {
            const res = await Auth.forgotPasswordSubmit(email, code, password)
            return {
                status: true,
                data: res
            }
        } catch (e) {
            console.log(e)
            return {
                status: false,
                error: e.message,
            }
        }
    }

    async function getDetails() {
        try {

            const user = await Auth.currentUserInfo()
            console.log(user)
            let data = await getUserInfo(user.id)
            console.log(data)
            const userData = await Auth.currentAuthenticatedUser();
            console.log(userData)

            await Auth.updateUserAttributes(userData, {
                'custom:userID': user.id,
                'custom:organization': "skypad"
            });

            if (Object.keys(data).length < 2 && !!user?.attributes?.preferred_username) {
                let data2 = await getUserInfo(user.attributes.preferred_username)

                data2['uid'] = user.id
                data2['userId'] = user.id

                console.log(data2)
                API.post('users', "/users", {
                    body: data2
                }).then(function (response) {
                    console.log(response)
                }).catch((e) => {
                    console.log(e.response)
                })
                data = data2
                const vehicleData = await getUserVehicle(user.attributes.preferred_username, userData.signInUserSession.accessToken.jwtToken)
                if (vehicleData && vehicleData.length > 0) {
                    for (let xm in vehicleData) {
                        console.log(vehicleData[xm])
                        const { ownerId, id, createdAt, name, vehicleTypeId } = vehicleData[xm]
                        console.log(ownerId, id, createdAt, name, vehicleTypeId)
                        const res = await axios.post(process.env.REACT_APP_VEHICLES_URL + `vehicle/`, {
                            ownerId: user.id,
                            id: id,
                            createdAt: createdAt,
                            name: name,
                            vehicleTypeId: vehicleTypeId
                        }, {
                            headers: { Authorization: "Bearer " + userData.signInUserSession.accessToken.jwtToken }
                        });
                        console.log(res)
                    }
                    console.log(vehicleData)
                }
                console.log(data2, vehicleData)
                const url = process.env.REACT_APP_ORGANIZATION_RELATION_URL
                const res2 = await axios.post(url + `organizationrelation/`, {
                    userId: user.id,
                    organizationId: process.env.REACT_APP_SKYPAD_ID
                }, {
                    headers: { Authorization: "Bearer " + userData.signInUserSession.accessToken.jwtToken }
                })
                console.log(res2)
            }

            console.log(data, user)




            if (!data.enabled) {
                alert("Account not enabled. Please contact the administrator to enable account.")
                await Auth.signOut({ global: true });
                await setCurrentUser(null)
                await setUserDetails(null)
                return {
                    status: false,
                    error: "Account not enabled. Please contact the administrator to enable account."
                }
            } else {
                if (user) {
                    setCurrentUser(user)
                }

                if (data) {
                    setUserDetails(data)
                }

                if (userData) {
                    setAuthData(userData)
                }

                setLoading(false)
                return {
                    status: true,
                    data: user
                }
            }

            // API.get('users', '/users/object/'+ user.username)
            // .then((response) => {
            //     console.log(response)
            //     setCurrentUser(user)
            //     setUserDetails(response)
            // })
        } catch (err) {
            console.log(err)
            setLoading(false)
        }
    }

    useEffect(() => {
        //   Hub.listen('auth', async ({ payload }) => {
        //     await setLoading(true)
        //     const { event } = payload;
        //     if (event === 'autoSignIn') {
        //         const user = payload.data;
        //         console.log(user)
        //         await getDetails()
        //         // assign user
        //     } else if (event === 'autoSignIn_failure') {
        //         // redirect to sign in page
        //         console.log("failed")
        //         await setCurrentUser(null);
        //     }
        //     await setLoading(false)
        //   })
        //console.log("Initializing")
        setLoading(true)
        //console.log(currentUser, userDetails)
        if (!currentUser || !userDetails) {
            //console.log("Fetching Details")
            getDetails()
        } else {
            setLoading(false)
        }


    }, [currentUser, userDetails, userInfo])

    const user = async () => { return await Auth.currentUserInfo() }

    const value = {
        user,
        currentUser,
        userDetails,
        userInfo,
        authData,
        getDetails,
        signup,
        resendConfirmationCode,
        confirmSignUp,
        login,
        logout,
        resetPassword,
        resetPasswordSubmit,
        loading
    }
    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
}