import {API} from 'aws-amplify';
const env = process.env.REACT_APP_ENVIRONMENT

export async function getUserInfo(user){
    console.log('getUserInfo', user)
    try {
        const res = await API.get(env === 'development' ? 'SkypadAPI' : 'users', "/users/object/"+user)
        console.log('getUserInfo', res)
        return res
    } catch (error) {
        console.log('getUserInfo', error)
        return null
    }
}

export async function getAllVersions(){
    try {
        const res = API.get('version', env === 'development' ? "/version/id" : "/version/")
        return res
    } catch (error) {
        console.log('getAllVersions', error)
        return null
    }
}