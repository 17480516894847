/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.
const env = process.env.REACT_APP_ENVIRONMENT
let awsmobile
if (env === 'development') {
    awsmobile = {
        "aws_project_region": "us-east-2",
        "aws_cloud_logic_custom": [
            {
                "name": "SkypadAPI",
                "endpoint": "https://vj4pnf1n9b.execute-api.us-east-2.amazonaws.com/testing",
                "region": "us-east-2"
            },
            {
                "name": "announcement",
                "endpoint": "https://8wqnkbhdm1.execute-api.us-east-2.amazonaws.com/testing",
                "region": "us-east-2"
            },
            {
                "name": "forms",
                "endpoint": "https://l5pbv4rm1a.execute-api.us-east-2.amazonaws.com/testing",
                "region": "us-east-2"
            },
            {
                "name": "gdoc",
                "endpoint": "https://oq712nih31.execute-api.us-east-2.amazonaws.com/testing",
                "region": "us-east-2"
            },
            {
                "name": "milicast",
                "endpoint": "https://xdlqt1cq2d.execute-api.us-east-2.amazonaws.com/testing",
                "region": "us-east-2"
            },
            {
                "name": "organizationRelation",
                "endpoint": "https://egd62qzyx0.execute-api.us-east-2.amazonaws.com/testing",
                "region": "us-east-2"
            },
            {
                "name": "organizations",
                "endpoint": "https://37w0m56uf4.execute-api.us-east-2.amazonaws.com/testing",
                "region": "us-east-2"
            },
            {
                "name": "session",
                "endpoint": "https://5g7mlab70e.execute-api.us-east-2.amazonaws.com/testing",
                "region": "us-east-2"
            },
            {
                "name": "settings",
                "endpoint": "https://m26kptqqmh.execute-api.us-east-2.amazonaws.com/testing",
                "region": "us-east-2"
            },
            {
                "name": "testsessions",
                "endpoint": "https://8xdl2bwl6a.execute-api.us-east-2.amazonaws.com/testing",
                "region": "us-east-2"
            },
            {
                "name": "version",
                "endpoint": "https://4mskvtuf98.execute-api.us-east-2.amazonaws.com/testing",
                "region": "us-east-2"
            }
        ],
        "aws_cognito_identity_pool_id": "us-east-2:c2ec7568-545c-4739-b16e-a46d2b49f29e",
        "aws_cognito_region": "us-east-2",
        "aws_user_pools_id": "us-east-2_yiO6Ard0Q",
        "aws_user_pools_web_client_id": "2c79ml9ev0d5nj0070u79cnan8",
        "oauth": {},
        "aws_cognito_username_attributes": [
            "EMAIL"
        ],
        "aws_cognito_social_providers": [],
        "aws_cognito_signup_attributes": [
            "EMAIL",
            "FAMILY_NAME",
            "GIVEN_NAME",
            "PHONE_NUMBER",
            "PICTURE"
        ],
        "aws_cognito_mfa_configuration": "OPTIONAL",
        "aws_cognito_mfa_types": [
            "SMS"
        ],
        "aws_cognito_password_protection_settings": {
            "passwordPolicyMinLength": 8,
            "passwordPolicyCharacters": []
        },
        "aws_cognito_verification_mechanisms": [
            "EMAIL"
        ],
        "aws_dynamodb_all_tables_region": "us-east-2",
        "aws_dynamodb_table_schemas": [
            {
                "tableName": "announcement-testing",
                "region": "us-east-2"
            },
            {
                "tableName": "forms-testing",
                "region": "us-east-2"
            },
            {
                "tableName": "organizationRelation-testing",
                "region": "us-east-2"
            },
            {
                "tableName": "organizations-testing",
                "region": "us-east-2"
            },
            {
                "tableName": "session-testing",
                "region": "us-east-2"
            },
            {
                "tableName": "sessions-testing",
                "region": "us-east-2"
            },
            {
                "tableName": "users-testing",
                "region": "us-east-2"
            },
            {
                "tableName": "version-testing",
                "region": "us-east-2"
            }
        ],
        "aws_user_files_s3_bucket": "web192851-testing",
        "aws_user_files_s3_bucket_region": "us-east-2"
    };
} else {
    awsmobile = {
        "aws_project_region": "us-east-1",
        "aws_cognito_identity_pool_id": "us-east-1:01bf76cf-56ab-40de-a781-ed17da7edacd",
        "aws_cognito_region": "us-east-1",
        "aws_user_pools_id": "us-east-1_LlTt50QqI",
        "aws_user_pools_web_client_id": "654cninkv1pclf2f5naaop7dgn",
        "oauth": {},
        "aws_cognito_username_attributes": [
            "EMAIL"
        ],
        "aws_cognito_social_providers": [],
        "aws_cognito_signup_attributes": [
            "EMAIL",
            "FAMILY_NAME",
            "GIVEN_NAME",
            "PHONE_NUMBER",
            "PICTURE"
        ],
        "aws_cognito_mfa_configuration": "OPTIONAL",
        "aws_cognito_mfa_types": [
            "SMS"
        ],
        "aws_cognito_password_protection_settings": {
            "passwordPolicyMinLength": 8,
            "passwordPolicyCharacters": [
                "REQUIRES_LOWERCASE",
                "REQUIRES_NUMBERS",
                "REQUIRES_SYMBOLS",
                "REQUIRES_UPPERCASE"
            ]
        },
        "aws_cognito_verification_mechanisms": [
            "EMAIL"
        ],
        "aws_user_files_s3_bucket": "web4b216-prod",
        "aws_user_files_s3_bucket_region": "us-east-1",
        "aws_dynamodb_all_tables_region": "us-east-1",
        "aws_dynamodb_table_schemas": [
            {
                "tableName": "organizations-staging",
                "region": "us-east-1"
            },
            {
                "tableName": "announcement-staging",
                "region": "us-east-1"
            },
            {
                "tableName": "forms-staging",
                "region": "us-east-1"
            },
            {
                "tableName": "organizationRelation-staging",
                "region": "us-east-1"
            },
            {
                "tableName": "session-staging",
                "region": "us-east-1"
            },
            {
                "tableName": "users-staging",
                "region": "us-east-1"
            },
            {
                "tableName": "vehicle-staging",
                "region": "us-east-1"
            },
            {
                "tableName": "vehicletype-staging",
                "region": "us-east-1"
            },
            {
                "tableName": "version-staging",
                "region": "us-east-1"
            }
        ],
        "aws_cloud_logic_custom": [
            {
                "name": "organization",
                "endpoint": "https://qxf8hoijcf.execute-api.us-east-1.amazonaws.com/prod",
                "region": "us-east-1"
            },
            {
                "name": "announcement",
                "endpoint": "https://98zaoub4d0.execute-api.us-east-1.amazonaws.com/prod",
                "region": "us-east-1"
            },
            {
                "name": "forms",
                "endpoint": "https://dk2ha9kpg9.execute-api.us-east-1.amazonaws.com/prod",
                "region": "us-east-1"
            },
            {
                "name": "organizationRelation",
                "endpoint": "https://5zy208w7k5.execute-api.us-east-1.amazonaws.com/prod",
                "region": "us-east-1"
            },
            {
                "name": "session",
                "endpoint": "https://bdakbn4c9d.execute-api.us-east-1.amazonaws.com/prod",
                "region": "us-east-1"
            },
            {
                "name": "users",
                "endpoint": "https://i82rjx8uhb.execute-api.us-east-1.amazonaws.com/prod",
                "region": "us-east-1"
            },
            {
                "name": "vehicle",
                "endpoint": "https://menysbdu89.execute-api.us-east-1.amazonaws.com/prod",
                "region": "us-east-1"
            },
            {
                "name": "vehicletype",
                "endpoint": "https://zgk5cov0t5.execute-api.us-east-1.amazonaws.com/prod",
                "region": "us-east-1"
            },
            {
                "name": "version",
                "endpoint": "https://qbm6d6mdc0.execute-api.us-east-1.amazonaws.com/prod",
                "region": "us-east-1"
            },
            {
                "name": "milicast",
                "endpoint": "https://wqb8goaf9d.execute-api.us-east-1.amazonaws.com/prod",
                "region": "us-east-1"
            }
        ]
    };
}

export default awsmobile;
