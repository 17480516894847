import React, { useEffect, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// eslint-disable-next-line
import PrivateRoute from "./PrivateRoute";
import "./index.css";
import "./App.css";

import Controller from "./components/controller";
import BackVideo from "./components/backvideo";

const Home = React.lazy(() => import("./pages/home/Home"));
const Signup = React.lazy(() => import("./pages/signup"));

const MOSHome = React.lazy(() => import("./pages/moshome"));
const Contact = React.lazy(() => import("./pages/contact"));
const UserJourney = React.lazy(() => import("./pages/userjourney"));
const Live = React.lazy(() => import("./pages/live"));
const Start = React.lazy(() => import("./pages/start"));
const CustomerJourney = React.lazy(() => import("./pages/customer"));
const ComingSoon = React.lazy(() => import("./pages/comingsoon/error"));

const Grid = React.lazy(() => import("./pages/grid"));
const Announcements = React.lazy(() => import("./pages/announcements"));
const Forgot = React.lazy(() => import("./pages/forgot"));
const Briefing = React.lazy(() => import("./pages/briefing"));
const Permissions = React.lazy(() => import("./pages/permissions"));
const Leaderboard = React.lazy(() => import("./pages/leaderboard"));
const Principles = React.lazy(() => import("./pages/principles"));
const Profile = React.lazy(() => import("./pages/profile"));



function App() {
    return (
        <div style={{ minHeight: "100vh" }}>
            <Router>
            <BackVideo videos={["https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-transcode.mp4", "https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-transcode.webm"]} poster={"https://uploads-ssl.webflow.com/5ea1030fc463ce106269d2ca/5ee260e3b906b8eca7cbb2e3_Evening Clouds Website-poster-00001.jpg"} />
                <Suspense fallback={<div style={{display: "flex", flexDirection: 'row', alignItems: 'center', justifyContent: 'center', position: 'fixed', height: '100vh', width: '100vw'}}><div className="loader"></div><p>Loading...</p></div>}>
                    <Routes>
                        <Route
                            path="/dashboard"
                            element={
                                <PrivateRoute>
                                    <CustomerJourney />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/grid"
                            element={
                                <PrivateRoute>
                                    <Grid />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/grid/:from/:to"
                            element={
                                <PrivateRoute>
                                    <Grid />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/announcements/:id"
                            element={
                                <PrivateRoute>
                                    <Announcements />
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/journey"
                            element={
                                <PrivateRoute>
                                    <UserJourney />
                                </PrivateRoute>
                            }
                        />

                        <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />

                        <Route path="/" element={<Home />} />
                        <Route path="/start" element={<Start />} />
                        <Route path="/live" element={<Live />} />
                        <Route path="/leaderboard" element={<Leaderboard />} />
                        <Route path="/principles" element={<Principles />} />
                        <Route path="/live/:sid" element={<Live />} />
                        <Route path="/briefing" element={<Briefing />} />
                        <Route path="/home" element={<MOSHome />} />
                        <Route path="/signup" element={<Signup />} />
                        
                        <Route path="/forgot" element={<Forgot />} />
                        <Route path="/contact" element={<Contact />} />
                        <Route path="/permissions" element={<Permissions />} />
                        <Route path="*" element={<ComingSoon />}></Route>
                    </Routes>
                </Suspense>
                <Controller />
            </Router>
        </div>
    );
}

export default App;
