import React from 'react'

const BackVideo = (props) => {
  const{videos, poster, otherClass} = props;

  return (
        <div className={["background-video", otherClass].join(" ")}>
            <video autoPlay={true} loop={true} poster={poster} muted={true} playsInline={true} preload="auto" style={{height: 'inherit', width: 'inherit', objectFit: 'cover'}} >
              {
                videos.map((data,index)=>{
                  return(<source src={data} key={index} />)
                })
              }
            </video>
        </div>
  )
}

export default BackVideo